import { Dispatch } from "redux";
import { footerNavTypes } from "app/components/FooterNav/types";
import { RouteService } from "app/services/route/route.service";
import { ROUTE_NAMES } from "app/services/route/route.types";
import { IRootState } from "app/reducers";
import { StateObject } from "@uirouter/react";
import { IAction, IActionGen } from "app/types/common.types";
import { AnalyticsService } from "app/services/analytics/analytics.service";
import { IVenue } from "app/models";
import { ClientService } from "app/services/client/client.service";
import { ISavedBookingMenuOption } from "app/services/booking/booking.types";
import { MenuOptionsService } from "app/services/menuOptions/menuOptions.service";
import { first } from "rxjs/operators";
import { IPaymentType } from "app/services/client/client.types";
import { LocationService } from 'app/services/location/location.service';
import { BookingService } from 'app/services/booking/booking.service';
import { IBookingMenuOption } from '../../services/booking/booking.types';
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {servicePaymentType} from "shared-types/index";

const NS = 'NavigationActionsNS';


export interface ITransitionPayload extends IAction {
  payload?: {
    from: ROUTE_NAMES;
    to: ROUTE_NAMES;
    id: number;
  };
}


export namespace NavigationActionsNS {

  // action type
  export enum Type {
    CHANGED_ROUTE_BY_NAME = 'CHANGED_ROUTE_BY_NAME',
    TRANSITION_STARTED = 'TRANSITION_STARTED',
    TRANSITION_FINISHED = 'TRANSITION_FINISHED',
    TRIED_NEXT = 'TRIED_NEXT',
    CHANGED_VENUE = 'CHANGED_VENUE',
    UPDATED_PAYMENT_TYPE = 'UPDATED_PAYMENT_TYPE',
    UPDATED_BOOKING_PAYMENT_DETAILS = 'UPDATED_BOOKING_PAYMENT_DETAILS',
    BLOCK_NAV_BECAUSE_OF_PAYMENT = 'BLOCK_NAV_BECAUSE_OF_PAYMENT',
    BLOCK_NAV_BECAUSE_OF_COVERS_PENDING = 'BLOCK_NAV_BECAUSE_OF_COVERS_PENDING',
    LOADING_PAYMENT_ON_NEXT = 'LOADING_PAYMENT_ON_NEXT',
    PASS_ROBOT_CHECK = 'PASS_ROBOT_CHECK',
  }

  // thunk action creators


  export const navPressed = (type: footerNavTypes) => (dispatch: Dispatch, getState: any): Promise<void> => {
    return new Promise(resolve => {

      const {navigation, widget}: IRootState = getState();
      const stepIndex: number = type === footerNavTypes.next
        ? RouteService.getNextRouteInSteps(navigation)
        : RouteService.getPrevRouteInSteps(navigation);

      const routeName = navigation.stepRoutes[stepIndex];

      const goToRoute = (isExistingBooking: boolean, hasPayment = false) => {

        if (isExistingBooking && hasPayment) {
          dispatch({type: Type.BLOCK_NAV_BECAUSE_OF_PAYMENT, payload: true} as IActionGen<boolean>);
          resolve();
        } else {
          dispatch({type: Type.BLOCK_NAV_BECAUSE_OF_PAYMENT, payload: false} as IActionGen<boolean>);
          RouteService.routeTo(routeName, dispatch, widget.appSettings, widget.activeVenue).then(() => {
            resolve();
          });
        }
      }

      const {activeService, activeVenue, booking} = widget;

      if (type === footerNavTypes.prev && routeName === ROUTE_NAMES.SITTING) {
        getUpsellFromSelectedMO(dispatch, booking.selectedMenuOptions)
      }

      /**
       * If editing an exiting booking, here we check if payments have been added to it and block 'next' navigation (NBI-1210)
       */
      const isExistingBooking = !!widget.savedBooking;

      if (type === footerNavTypes.next && routeName === ROUTE_NAMES.CUSTOMER_DETAILS) {

        const selectedMenuOptions: ISavedBookingMenuOption[] = MenuOptionsService.getFlatExtras(booking.selectedMenuOptions);

        dispatch({type: BookingActionsTypes.IS_UPSELL_PRESENT, payload: false})
        // first checks if payment on booking options exists and blocks next nav if so
        if (selectedMenuOptions && selectedMenuOptions.length) {
          dispatch({type: Type.LOADING_PAYMENT_ON_NEXT, payload: true});

          ClientService.getPaymentType((activeVenue as IVenue).id, selectedMenuOptions, activeService.id, booking.utcTime, booking.covers)
            .pipe(first())
            .subscribe(result => {
              dispatch({type: Type.LOADING_PAYMENT_ON_NEXT, payload: false});
              dispatch({type: Type.UPDATED_PAYMENT_TYPE, payload: result.data} as IActionGen<IPaymentType>);

              if(activeVenue.widgetSettings.enableAdvancedEditingSupport){
                //Allow editing existing bookings that are preauth.
                goToRoute(isExistingBooking, result.data.paymentTypeName !== servicePaymentType.noPayment && result.data.paymentTypeName !== servicePaymentType.preAuth);
              } else {
                goToRoute(isExistingBooking, result.data.paymentTypeName !== servicePaymentType.noPayment);
              }
            }, err => {
              /**
               * An error here will force a generic payment message on summary screen.
               * We don't really need to show an error message to the end user if this happens, since the next screen will be the payment screen,
               * which has different back end logic, and will probably not contain any errors.
               */
              dispatch({type: Type.LOADING_PAYMENT_ON_NEXT, payload: false});
              dispatch({type: Type.UPDATED_PAYMENT_TYPE, payload: null} as IActionGen<IPaymentType>);
              goToRoute(isExistingBooking);
            });
          return;
        }


        // then checks if payment on service exists and blocks next nav if so
        const hasPaymentOnService: boolean = activeService && BookingService.isPaymentDetailsVisible(booking.covers, activeService.paymentDetails);
        if (hasPaymentOnService) {
          if(activeVenue.widgetSettings.enableAdvancedEditingSupport){
            const hasPayment = activeService.paymentDetails.paymentType !== servicePaymentType.preAuth && isExistingBooking;
            goToRoute(isExistingBooking, hasPayment);
            return;
          } else {
            goToRoute(isExistingBooking, true);
          }
          return;
        }
      }

      // anything else is allowed to proceed to the next screen
      goToRoute(isExistingBooking);
    });
  }

  export const getUpsellFromSelectedMO = (dispatch: Dispatch, selectedMenuOption: IBookingMenuOption[]) => {
    const selectedUpsellOptions: IBookingMenuOption[] = selectedMenuOption.filter(({isUpsellItem}) => isUpsellItem)
    const updatedSelectedMenuOption: IBookingMenuOption[] = selectedMenuOption.filter(({isUpsellItem}) => !isUpsellItem)

    const payload = {
      selectedUpsellOptions,
      updatedSelectedMenuOption
    }

    dispatch({type: BookingActionsTypes.SEGREGATE_SELECTED_MENU_OPTION_UPSELL, payload: payload})
  }

  export const triedNext = (reset = false) => (dispatch: Dispatch, getState: any) => {
    return new Promise(resolve => {
      const {navigation}: IRootState = getState();
      // if no payload is sent it will reset the triedNext prop in store
      dispatch({type: Type.TRIED_NEXT, payload: reset ? null : navigation.currentRouteName});
    });
  }
  export const passRobotCheck = (reset = false) => (dispatch: Dispatch, getState: any) => {
    dispatch({type: Type.PASS_ROBOT_CHECK, payload: null});
  }

  export const reload = (clearParams = false) => (dispatch: Dispatch, getState: any) => {
    return new Promise(resolve => {

      // reloads the app from the start with same url parameters
      const {widget}: IRootState = getState();

      if (clearParams) {
        // strips out token and edit from url so it loads page in non-edit (standard) mode
        location.assign(LocationService.stripCurrentUrl(widget.appSettings.startURL, true, true));
      } else {
        location.assign(widget.appSettings.startURL);
      }
    });
  }

  export const changedRouteByName = (nextRouteName: ROUTE_NAMES) => (dispatch: Dispatch, getState: any): Promise<string> => {
    return new Promise<string>(resolve => {
      const {widget} = getState();
      RouteService.routeTo(nextRouteName, dispatch, widget.appSettings, widget.activeVenue).then((routeData: StateObject) => {
        resolve(routeData.name);
      });
    });
  }


  export const transitionStarted = (from: ROUTE_NAMES, to: ROUTE_NAMES, id: number) => (dispatch: Dispatch, getState: any): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: Type.TRANSITION_STARTED, payload: {from, to, id}} as ITransitionPayload);
      resolve();
    });
  }


  export const transitionFinished = (from: ROUTE_NAMES, to: ROUTE_NAMES, id: number) => (dispatch: Dispatch, getState: any): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: Type.TRANSITION_FINISHED, payload: {from, to, id}} as ITransitionPayload);
      resolve();
    });
  }

  export const changedVenue = (venueId: number) => (dispatch: Dispatch, getState: any): Promise<void> => {

    return new Promise<void>(resolve => {
      dispatch({type: Type.CHANGED_VENUE, payload: venueId});

      // must come after `CHANGED_VENUE` dispatch so that `activeVenue` is set
      const {widget} = getState();
      AnalyticsService.init(widget.activeVenue as IVenue);
      resolve();
    });
  }


  export const coversPending = () => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: Type.BLOCK_NAV_BECAUSE_OF_COVERS_PENDING});
      resolve();
    });
  }


}
