import {Moment} from "moment";
import {
  PricingTypes,
  servicePaymentType,
  ISectionOrder, IBookingTag, IBookedByCustomerDetail
} from "shared-types/index";

export interface IBookingPayment {
  amountPaid?: number; // amountPaid will be undefined if pre-auth is not yet authorized
  price: number;
  fee?: number;
  paymentType: servicePaymentType;

  amountDue: number;
  discountAmount: number;
  peopleRequired: number;
  promotionCode?: string;
  discountApplied?: boolean;

  transactionId?: string;
  currency?: string; // think this is just for pre-auth
  pricingType?: PricingTypes;
  cardLast4?: string;
}

export interface IServiceDetails {
  id: string;
  duration: number; // mins
  serviceType: string;
  name: string; // 'Dinner'
}

export interface ICustomer {
  _id?: string;
  company?: string;
  postcode?: string;
  membershipNumber?: string;
  birthdayDate?: number;
  birthdayMonth?: number;
  birthdayYear?: number;
  email: string;
  firstName: string;
  lastName: string;
  notes?: string;
  phone: string;
  phoneNational?: string;
  subscribed: boolean; // this corresponds to the terms and conditions checbox
  termsAgreementChecked?: boolean;
}

export interface IBookingMenuOptionExtras {
  isSameForAll: boolean;
  explicitChildMenuOptions: IBookingMenuOption[][]; // multi-dimensional array
  implicitChildMenuOptions: IBookingMenuOption[];
}

export interface IBookingMenuOptionExtrasUpdater extends IBookingMenuOptionExtras {
  parentId?: string;
  parentLabel: string;
  upsellDescription?: string;
  parentQuantity: number;
}

export interface IBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: IBookingMenuOptionExtras; // expanded list of child booking options
  isUpsellItem?: boolean; // will be true for upsell BO
}

export interface ISavedBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: ISavedExtrasOption[]; // flat list of child booking options for backend
  isUpsellItem?: boolean; // will be true for upsell BO
}

export interface ISavedExtrasOption {
  quantity: number;
  menuOptionId: string;
  isExplicit: boolean;
}


export interface IBookingOutgoing {
  customer: ICustomer;
  time: string; // "2019-11-14T19:30:00"
  notes: string;
  hasManagerNotes: boolean;
  hasCustomerNotes: boolean;
  selectedMenuOptions: ISavedBookingMenuOption[];
  numOfPeople: number;
  sectionId: string; // 'all'
  service: IServiceDetails;
  NoDelete?: boolean; // Just used for updateBooking. Should be determined by BookingService.shouldPay
  tags: IBookingTag[];
  bookedBy: IBookedByCustomerDetail;
}

export interface IBookingIncoming {
  covers: number;
  isFromDiary: boolean;
  locked: boolean;// null
  moment: Moment;
  onlineEditingDisabled: boolean;// null
  rhinoTime: string;
  sectionId: string; // 'any'
  serviceId: string; // "service_Z8PVZN5HROZHU_1570589968666"
  serviceName: string; // "Dinner"
  status: bookingStatusType; // "unconfirmed"
  utcTime: string; // "2019-11-14T19:30:00"
  viewDate: string | boolean; // "Thursday, November 14, 2019" // this probably will change to just be null, rather than supporting boolean
  viewTime: string; // "7:30 pm"
}

export interface ICoversOrServiceChange {
  booking: IBooking;
  filteredSections: ISectionOrder[];
  activeSection: ISectionOrder;
}


export interface IPrivateFunction {
  isPaid: boolean;
  time: string;
  people: number;
  status: IBookingStatus;
  serviceId: string;
  serviceName: string;
  locked: boolean;
  onlineEditingDisabled: boolean;
  customer: IBookingCustomer;
  preferredSectionId?: string; // "section_GIYOEZ9CC963K"
  sectionId?: string; //"section_AEMOGNHCZ4IV0"
  tags?: IBookingTag[] | IBookingTag;

  paymentPending?: IBookingPayment;
  paymentSummary?: IPaymentSummary; // null
  onlineCancelDisabled: boolean;

  bookedBy?: IBookedByCustomerDetail;
  isBookedBy?: boolean;
}


export interface IBookingCustomer extends ICustomer {
  birthdayDate?: number;
  birthdayMonth?: number;
  birthdayYear?: number;
  lastModifiedDate: string; // "2019-11-29T02:11:07.2713648+00:00"
  managerNotes: string;
  noShowCount: number; // 0
  phone2: string;
  postcode: string;
  streetAddress: unknown;
  suburb: unknown;
  tags: IBookingTag[];
  type: string; // "customer"
}

export interface IBookingStatus {
  code: bookingStatusCode;
  editable: boolean;
  enabled: boolean;
  hide: boolean;
  name: bookingStatusName;
  order: number
  statusType: bookingStatusType;
  type: string; // "status"
}

export interface IPaymentSummary {
  amountPaid: number; // 10
  amountDue: number;
  paymentType: servicePaymentType;
  amount: number;
  currencyCode: string; // "AUD"
  hasPromotion: boolean;
  paymentDate: string;
  preAuthChargeIfWithinHours: number; // 24
  preAuthChargedAmount: number; // 0
  preAuthChargedDate: string;
  preAuthHeldDate: string; // "2020-12-29T06:03:26.8988100+00:00"
  preAuthReleasedAmount: number; // 10
  preAuthReleasedDate: string; // "2020-12-29T06:18:14.6736843+00:00"
  preAuthStatus: preAuthStatus;
  promotionCode: unknown;
  transactionId: unknown;
  cardLast4?: string; //Last four digits of credit card used to pay a booking.
}

export interface IBookingResponseData {
  bookingRef: string; // "32582819"
  method: string; // "online"
  time: string; // "2019/11/30 09:30"
  orgTime: string; // "2019/11/30 09:30"
  duration: number; // 30
  people: number; // covers
  customer: IBookingCustomer;
  tables: IBookingTable[];
  preferredSectionId: string; // "section_GIYOEZ9CC963K"
  tags: IBookingTag[];
  status: IBookingStatus;
  hasManagerNotes: boolean;
  hasCustomerNotes: boolean;
  bookedAt: string; // "2019-11-29T02:03:02.8191738+00:00"
  paymentSummary: IPaymentSummary; // null
  serviceId: string; // "service_SY3N885VPA6GG_1570589968666"
  lastModifiedDate: string; // "2019-11-29T02:03:02.8191738+00:00"
  serviceName: string; //  "Morning Tea"
  locked: boolean;
  onlineEditingDisabled: boolean;
  paymentPending: IBookingPayment; // null
  venueID: unknown;
  _id: string; // "booking_2019-11-30_ZDUVF7R0HU4D3_1575032582882"
  type: string; // "booking"
  bookingType: BookingType;
  selectedOptions: ISavedBookingSelectedOptions[];
  standByConfirmationExpiry: Date;

  // when customer is null, these may exist
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  phone?: string;
  notes?: string;
  onlineCancelDisabled: boolean;

  bookedBy?: IBookedByCustomerDetail;
  isBookedBy?: boolean;
}

export interface ISavedBookingSelectedOptions {
  extras: ISavedExtras[];
  id: string;// "menuoption_GCYRCFH5MFORV_1589160518076"
  label: string; // "free buffet"
  name: string; // "free buffet"
  paymentType: servicePaymentType;
  price: number; // 0
  quantity: number; // 2
  isUpsellItem?: boolean
}

export interface ISavedExtras {
  id: string; // "menuoption_YOSOQUWBWJO8N_1587443464464"
  isExplicit: boolean; // true
  label: string; // "Free lunch"
  name: string; // "Free lunch"
  paymentType: servicePaymentType;
  price: number; // 0
  quantity: number; // 1
}


export interface IBookingTable {
  capacity: number;
  chairs: unknown;
  minCapacity: number;
  name: string; // "2"
  online: boolean;
  order: number;
  position: unknown;
  priority: number; // 1
  rotation: number; //0
  sectionId: string; //"section_AEMOGNHCZ4IV0"
  shape: string; // "square"
  shared: boolean;
  type: unknown; // null
  walkinOnly: boolean;
}


export enum BookingType {
  Booking = 'Booking',
  WaitList = 'WaitList',
  StandbyList = 'StandbyList'
}

export interface IBookingAbstract {
  _id?: string; // if booking has been saved it should have an _id

  // not sure about these properties. they don't exist when retreiving existing booking
  accountId?: string | boolean; // this probably will change to just be null, rather than supporting boolean
  venueId?: string | boolean; // this probably will change to just be null, rather than supporting boolean
  // venueIndex: number; // don't need this (can work it out from venueId, which is better anyway)

   // @toDo: double check this actually gets used. Seems like 'moment' gets used instead. severity: low
  date?: string | boolean; // this probably will change to just be null, rather than supporting boolean

  selectedMenuOptions?: IBookingMenuOption[];
  selectedUpsellOptions?: IBookingMenuOption[];

  viewDate: string;
  covers: number;
  serviceId: string;
  sectionId: string;
  utcTime: string;
  viewTime: string;
  rhinoTime: string;
  payment: IBookingPayment;

  isFromDiary?: boolean;

  moment: Moment; // gets added in addUrlParamsToBooking (used for events) @toDo determine if this could be a simple date string, rather than a moment object. severity: medium
  serviceIds?: string[]; // gets added in addUrlParamsToBooking

  tags?: IBookingTag[];
  savedTags?: string[]; // just the tag ids

  status?: bookingStatusType;
  serviceName?: string; // "Dinner"
  locked?: boolean;// null
  onlineEditingDisabled?: boolean;// null
  notes?: string;
  bookingType: BookingType;
  tables?: IBookingTable[];
  onlineCancelDisabled?: boolean;
  method?:string;
}

export interface IBooking extends IBookingAbstract {
  orgDate?: Moment;
  customer: ICustomer;
  standByConfirmationExpiry?: Date;
  hasManagerNotes?: boolean;
  hasCustomerNotes?: boolean;
  isGaw?: boolean;
  bookedBy?: IBookedByCustomerDetail;
  isBookedBy?: boolean;
}

export interface ISavedBooking extends IBookingAbstract {
  time: string; // "2019-11-14T19:30:00" (used for savedBooking)
  bookingId: string;
  duration: number; // mins
  notes: string;
}

export interface IManageBookingConfig {
  title: string;
  message: string;
  hideDetails: boolean;
  hideButtons: boolean;
}

/**
 * These statuses are actually not concrete, since you can add more in diary
 */
export enum bookingStatusType {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  seated = 'seated',
  finished = 'finished',
  standbyPending = 'standby-pending',
  pendingPayment = 'pending-payment',
  partiallySeated = 'partially-seated'
}

export enum bookingStatusName {
  unconfirmed = 'Unconfirmed',
  confirmed = 'Confirmed',
  cancelled = 'Cancelled',
  seated = 'Seated',
  finished = 'Finished',
  standbyPending = 'Standby Pending',
  pendingPayment = 'Payment Pending'
}

export enum bookingStatusCode {
  unconfirmed = 'U',
  confirmed = 'C',
  cancelled = 'X',
  noShow = 'NS', // this doesn't exist in bookingStatusType
  seated = 'S',
  finished = 'F',
  standbyPending = 'SP'
}

export enum preAuthStatus {
  released = 'Released',
  holding = "Holding"
}
