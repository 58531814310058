import { IAccountDetails } from "./account.types";
import { IVenueMinimal, IVenue } from "app/models";
import * as Mustache from 'mustache';
import { IOwnedVenue, IPaymentSettings, IEwayInfo, paymentProviderType, IStripeInfo, paymentProviderTypeUppercase } from "../client/client.types";
import appValues from "app/constants/appValues";

const NS = 'AccountService';

export class AccountService {

  static getEmptyAccountDetails(): IAccountDetails {
    return {
      id: null,
      firstName: null,
      surname: null,
      email: null,
      businessName: null,
      businessId: null,
      businessUrl: null,
      businessAddress: null,
      suburb: null,
      postcode: null,
      state: null,
      country: null,
      city: null,
      active: false,
      logoUrl: null,
      contactNumber: null,
      alternativePhone: null,
      currentBookingService: null,
      ownedVenues: [],
      accountPhone: null
    }
  }

  /**
   * Returns a venue object with pre-populated data. If venueId is not passed, creates a 'mock' venue object,
   * based on `accountDetails` data. This could happen, for example, via `termsAndConditions`, which gets its
   * data from a query string param.
   */
  static getVenue(accountDetails: IAccountDetails, venueId: number): IVenueMinimal | IVenue {
    if (!venueId) {
      return this.getVenueMinimal(accountDetails);
    }

    const venue: IOwnedVenue = accountDetails.ownedVenues.find(v => v.id === venueId);
    if (!venue) {
      return null;
    }

    return this.addDefaultVenueValues(
      this.convertOwnedVenueToVenue(venue, accountDetails)
    );
  }

  private static getVenueMinimal(accountDetails: IAccountDetails): IVenueMinimal {
    return {
      active: accountDetails.active,
      name: accountDetails.businessName,
      phone: accountDetails.contactNumber,
      city: accountDetails.city,
      state: accountDetails.state || null,
      country: accountDetails.country,
      email: accountDetails.email,
      logoUrl: accountDetails.logoUrl,
      widgetSettings: null,
      paymentSettings: null,
      canCancelBookingBySms: false,
      canCustomersCancelBookings: false,
      canCustomersEditBookings: false,
      canEditBookingBySms: false,
      url: ''
    };
  }

  private static addDefaultVenueValues(venue: IVenue): IVenue {
    const textReplace = {
      bookingCancellationWindow: venue.widgetSettings.bookingCancellationWindow || '',
      preAuthReleasingWindow: venue.widgetSettings.preAuthReleasingWindow || ''
    };

    if (!venue.widgetSettings.maxPeoplePerBooking) {
      venue.widgetSettings.maxPeoplePerBooking = appValues.MAX_PEOPLE_PER_BOOKING;
    }

    if (!venue.widgetSettings.termsAndConditionsMessage) {
      venue.widgetSettings.termsAndConditionsMessage = '(Terms and Conditions not set)';
    }

    // Interpolate cancellation window
    venue.widgetSettings.preAuthorisationMessage = Mustache.render(venue.widgetSettings.preAuthorisationMessage, textReplace);

    return venue;
  }

  private static convertOwnedVenueToVenue(venue: IOwnedVenue, accountDetails: IAccountDetails): IVenue {

    return {
      accountId: accountDetails.id,
      id: venue.id,
      active: venue.active,
      name: venue.name,
      venueName: venue.name,
      phone: venue.phone,
      city: null, // venue.city, // doesn't exist on IOwnedVenue
      country: venue.country,
      state: venue.state || null,
      currency: venue.currency,
      email: venue.contactEmail,
      timeZoneId: venue.timeZoneId,
      widgetSettings: venue.widgetSettings,
      logoUrl: (!venue.logoUrl && accountDetails.logoUrl) ? accountDetails.logoUrl : venue.logoUrl,
      paymentSettings: this.getPaymentSettings(venue.paymentSettings),
      canCancelBookingBySms: venue.canCancelBookingBySms,
      canCustomersCancelBookings: venue.canCustomersCancelBookings,
      canCustomersEditBookings: venue.canCustomersEditBookings,
      canEditBookingBySms: venue.canEditBookingBySms,
      clientSideEncryptKey: this.getClientSideEncryptKey(venue.paymentSettings),
      preAuthReleasingWindow: venue.paymentSettings.preAuthReleasingWindow,
      analytics: {
          facebookApi: venue.analytics && venue.analytics.facebook && venue.analytics.facebook.apiKey ? venue.analytics.facebook.apiKey : null,
          googleApi: venue.analytics && venue.analytics.google && venue.analytics.google.apiKey ? venue.analytics.google.apiKey : null,
          googleGTMFields: venue.analytics?.google?.gtmFields || [],
      },
      groupAvailabilityActive: venue.groupAvailabilityActive,
      groupAvailabilitySubscriptionActive: venue.groupAvailabilitySubscriptionActive,
      groupAvailabilityThreshold: venue.groupAvailabilityThreshold,
      url: venue.url
    }
  }

  /**
   * Annoyingly IOwnedVenue has uppercase first letter.
   * So here we must convert it to lowercase before returning the payment into.
   */
  private static getPaymentSettings(data: IPaymentSettings): IEwayInfo | IStripeInfo {
    if (!data || !data.paymentProvider) {
      return null;
    }

    if (data.paymentProvider === paymentProviderTypeUppercase.Eway) {
      data.eway.paymentProvider = paymentProviderType.eway;
      return data.eway;
    }

    if (data.paymentProvider === paymentProviderTypeUppercase.Stripe) {
      data.stripe.paymentProvider = paymentProviderType.stripe;
      return data.stripe;
    }

    return null;
  }

  private static getClientSideEncryptKey(data: IPaymentSettings): string {
    if (!data || !data.paymentProvider) {
      return null;
    }

    if (data.paymentProvider === paymentProviderTypeUppercase.Eway) {
      return data.eway.clientSideEncryptionKey;
    }

    // clientSideEncryptionKey doesn't exist for stripe

    return null;
  }
}
