import { wrapperStyleType } from "app/models";
import {IScheduleService, ISectionOrder, IWidgetTheme} from "shared-types/index";

export interface ISectionSelector {
  handleSelect: (id: string) => void;
  wrapperStyle: wrapperStyleType;
  activeService: IScheduleService;
  sections: ISectionOrder[];
  activeSectionId: string;
  theme: IWidgetTheme;
  isStandbyMode: boolean;
}

export const SECTION_ANY_ID = 'any';
export const SECTION_ANY_NAME = 'Any';

export function getSectionAny(): ISectionOrder {
  return {
    id: SECTION_ANY_ID,
    name: SECTION_ANY_NAME,
    order: 0
  }
}
