import {cloneDeep, sortBy, uniqBy, flatten, orderBy} from 'lodash';
import {
  bookingStatusCode,
  bookingStatusType,
  BookingType,
  IBooking,
  IBookingMenuOption,
  IBookingMenuOptionExtrasUpdater,
  IBookingPayment,
  IBookingResponseData,
  ICoversOrServiceChange,
  ICustomer,
  IPaymentSummary,
  IPrivateFunction,
  ISavedBooking,
  ISavedBookingSelectedOptions,
  preAuthStatus
} from "./booking.types";
import moment, {Moment} from "moment-timezone";
import {IAppSettings, IStandbyData, IVenue, IWidgetModel} from "app/models";
import {
  IAdditionalBookingRequirements, IOwnedVenue,
  ISchedule,
  ISectionState
} from "../client/client.types";
import {loadStatus} from "app/types/common.types";
import {MessageService} from "../message/message.service";
import {SECTION_ANY_ID, SECTION_ANY_NAME} from "app/components/SectionSelector/types";
import appValues from "app/constants/appValues";
import DateUtilsService from "shared-services/date-utils-service";
import {ISelectableTime} from "app/components/TimePicker/types";
import {bookingAction} from "../route/route.types";
import {MenuOptionsService} from '../menuOptions/menuOptions.service';
import {isEmpty} from "lodash";
import {
  IServicePaymentOption,
  servicePaymentType,
  IServicePaymentDetails,
  IScheduleTime,
  ISectionOrder,
  IScheduleService,
  IBookingTag
} from "shared-types/index";


const NS = 'BookingService';
export const GET_SCHEDULE_LOADING_FAILED_MSG = 'Oops... something went wrong when retrieving availabilities for this day.';
export class BookingService {


  // @todo WIP - need to finish it and use it
  static getBookingStatus(code: bookingStatusCode) {
    return [
      {
        "code": bookingStatusCode.unconfirmed,
        "name": "Unconfirmed",
        "statusType": bookingStatusType.unconfirmed,
        "enabled": true,
        "editable": false,
        "order": 1,
        "type": "status",
        "statusEditable": true,
        "_id": "status_unconfirmed"
      },
      {
        "code": bookingStatusCode.confirmed,
        "name": "Confirmed",
        "statusType": bookingStatusType.confirmed,
        "enabled": true,
        "editable": false,
        "order": 2,
        "type": "status",
        "_id": "status_confirmed"
      },
      {
        "code": bookingStatusCode.cancelled,
        "name": "Cancelled",
        "statusType": bookingStatusType.cancelled,
        "enabled": true,
        "editable": false,
        "order": 4,
        "type": "status",
        "_id": "status_cancelled"
      },
      {
        "code": bookingStatusCode.noShow,
        "name": "No Show",
        "statusType": bookingStatusType.cancelled,
        "enabled": true,
        "editable": true,
        "order": 5,
        "type": "status",
        "_id": "status_cancelled_no-show"
      },
      {
        "code": bookingStatusCode.seated,
        "name": "Seated",
        "statusType": bookingStatusType.seated,
        "enabled": true,
        "editable": true,
        "order": 9,
        "type": "status",
        "_id": "status_seated"
      },
      {
        "code": bookingStatusCode.finished,
        "name": "Finished",
        "statusType": bookingStatusType.finished,
        "enabled": true,
        "editable": true,
        "order": 15,
        "type": "status",
        "_id": "status_finished"
      }
    ];
  }

  static getBookingReset(): IBooking {
    const _moment: Moment = moment();
    return {
      accountId: null,
      venueId: null,
      date: null,
      viewDate: this.getViewDateFromMoment(_moment),
      utcTime: null,
      covers: 0,
      serviceId: null,
      sectionId: null,
      viewTime: null,
      rhinoTime: null,
      selectedMenuOptions: [],
      customer: this.getEmptyCustomer(),
      payment: {
        paymentType: servicePaymentType.noPayment,
        amountPaid: 0,
        price: 0,
        amountDue: 0,
        discountAmount: 0,
        peopleRequired: 0
      },
      moment: _moment,
      bookingType: BookingType.Booking,
      hasCustomerNotes: false,
      hasManagerNotes: false
    }
  }

  static filterTags(tags: IBookingTag[]): IBookingTag[] {
    const filteredTags: IBookingTag[] = [];

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      if (tag.selected) {
        filteredTags.push(tag);
      }
    }
    return filteredTags;
  }


  static mergeTags(tags: IBookingTag[], savedTags: IBookingTag[]): IBookingTag[] {
    const offlineTags = savedTags.filter(tag => !tag.online);
    return tags.concat(offlineTags);
  }

  static getViewDateFromMoment(moment: Moment): string {
    return moment.format('dddd, MMMM D, YYYY');
  }

  static addDateParamToBooking(booking: IBooking, dateStr: string): void {
    const date = moment(dateStr, 'YYYY-M-D');
    booking.moment = date;
    booking.viewDate = BookingService.getViewDateFromMoment(date);
    booking.utcTime = date.format();
    booking.orgDate = date; // To save the original date incase the booking date is overwritten.
  }

  static addUrlParamsToBooking(booking: IBooking, appSettings: IAppSettings) {
    // date handled in addDateParamToBooking
    booking.covers = appSettings.covers;
    booking.serviceIds = appSettings.serviceids.split(',');
    booking.serviceId = this.getFirstFromCommaSepartedString(appSettings.serviceids);
  }

  static addUrlParamsToBookingForGAW(booking: IBooking, appSettings: IAppSettings) {
    // date handled in addDateParamToBooking
    booking.covers = appSettings.covers;
  }

  static isPrivateFuncEvent(appSettings: IAppSettings): boolean {
    const {payment, payments} = bookingAction;
    return appSettings.serviceids && (appSettings.action === payment || appSettings.action === payments);
  }

  static isStandardEvent(appSettings: IAppSettings): boolean {
    const {payment, payments} = bookingAction;
    return appSettings.serviceids && (appSettings.action !== payment && appSettings.action !== payments);
  }

  static getFirstFromCommaSepartedString(str: string): string {
    if (!str) {
      return null;
    }

    if (str.indexOf(',') === -1) {
      return str;
    }

    const arr = str.split(',');
    return arr[0];
  }

  static removeOfflineServices(schedule: ISchedule) {
    schedule.services = schedule.services.filter(s => s.online);
    return schedule;
  }

  /**
   * Checks if schedule can load and returns a message
   */
  static getScheduleMsg(schedule: ISchedule, services: IScheduleService[], venue: IVenue, scheduleLoadStatus: loadStatus): string {

    if (scheduleLoadStatus === loadStatus.failed || scheduleLoadStatus === loadStatus.failedTemp) {
      return GET_SCHEDULE_LOADING_FAILED_MSG;
    }

    if (!schedule) {
      return null; // do nothing is schedule hasn't loaded
    }

    if (!schedule.isVenueOpen) {
      return (schedule.blockoutMessage) ? schedule.blockoutMessage : MessageService.get('dayClosedMessage', venue)
    }

    if (!services.length && venue.widgetSettings.serviceClosedMessage) {
      return MessageService.getMessage(venue.widgetSettings.serviceClosedMessage, venue, null);
    }

    if (!this.omitExpiredServices(services).length) {
      return MessageService.get('timeNoLongerAvailableMessage', venue);
    }

    return null;
  }

  static getMaxPeoplePerBooking(activeVenue: IVenue, service: IScheduleService, schedule = null as ISchedule): number {
    if (!activeVenue) {
      return appValues.MAX_PEOPLE_PER_BOOKING;
    }

    // if service is selected then we take the override from the service - else we get the max override from the schedule as default.
    if (service && service.paymentDetails) {
      return service.paymentDetails.maxPeoplePerBookingOverride || activeVenue.widgetSettings && activeVenue.widgetSettings.maxPeoplePerBooking || appValues.MAX_PEOPLE_PER_BOOKING;
    }
    const maxOverrideFromService = this.findMaxPeopleOverrideFromServices(schedule);
    if (!maxOverrideFromService) {
      return activeVenue.widgetSettings && activeVenue.widgetSettings.maxPeoplePerBooking || appValues.MAX_PEOPLE_PER_BOOKING;
    } else {
      return maxOverrideFromService
    }
  }

  /**
   * Filters out services that have either expired or have `times` set to null ('Allow in online booking system: No')
   */
  static getAvailableServicesFromSchedule(schedule: ISchedule, omitExpired = true): IScheduleService[] {
    if (!schedule) {
      return [];
    }
    if (omitExpired) {
      return this.omitExpiredServices(schedule.services);
    }
    return schedule.services;
  }

  static omitExpiredServices(services: IScheduleService[]): IScheduleService[] {
    return services.filter(s => s.times && s.times.find(t => !t.expired));
  }

  /**
   * If a service has any expired times it means that it has started or is in the past.
   * Could potentially need to use `BookingService.getVenueTime(activeVenue as IVenue)` in future if current time is needed.
   */
  static isServiceActiveOrComplete(times: IScheduleTime[]): boolean {
    return times.some(t => t.expired);
  }

  /**
   * If customers can choose a section, don't auto-select the first service.
   * This gives the user the chance to consciously open the next panel.
   */
  static preselectFirstService(services: IScheduleService[]): IScheduleService {
    return services && services.length
      ? services[0]
      : null;
  }

  /**
   * Quick check to see if service has a payment on it, not taking into account booking options or if price is more than
   * zero. For most cases you should use `isPaymentDetailsVisible` instead, as this contains checks for the above mentioned
   * scenarios.
   * @todo: replace instances of `hasPayment` and replace with `isPaymentDetailsVisible`, then rename to something more intuitive. severity: low
   */
  static hasPayment(service: IScheduleService, covers: number): boolean {
    if (!service) {
      return false;
    }
    return service.paymentDetails.paymentType !== servicePaymentType.noPayment && covers >= service.paymentDetails.peopleRequired;
  }

  /**
   * Determines if a service has booking options
   * @param maxPeoplePerBookingDefault - comes from `widget.activeVenue.widgetSettings.maxPeoplePerBooking`
   */
  static hasMenuOptions(service: IScheduleService, covers: number, maxPeoplePerBookingDefault: number): boolean {
    if (!service) {
      return false;
    }

    const menuOptions: IServicePaymentOption[] = service.paymentDetails.options || [];
    const maxOverride: number = service.paymentDetails.maxPeoplePerBookingOverride || maxPeoplePerBookingDefault;
    const peopleRequired: number = service.paymentDetails.peopleRequired;

    return !!menuOptions.length && covers >= peopleRequired && covers <= maxOverride;
  }


  /**
   * Populates standbyData object based on properties in booking object
   * @param booking - the booking object to get data from
   * @param existingStandbyData - optionally pass in an existing standbyData object to extend upon
   * @param clearBookingData - optionally clear the booking object's fields
   */
  static getStandbyDataFromBooking(booking: IBooking, existingStandbyData?: IStandbyData, filteredTimes?: ISelectableTime[]): IStandbyData {
    const {
      sectionId, viewDate, covers,
      serviceId, serviceName, customer, tags
    } = booking;

    let standbyData: IStandbyData;
    if (existingStandbyData) {
      standbyData = {
        ...existingStandbyData,
        sectionId
      }
      if (filteredTimes) {
        const matchedTime = filteredTimes.find(t => !t.isDisabled && standbyData.utcTime === t.time);
        if (matchedTime) {
          matchedTime.isSelected = false;
          standbyData.utcTime = null;
          standbyData.viewTime = null;
        }
      }
    } else {
      standbyData = {
        sectionId, viewDate, covers,
        serviceId, serviceName, customer, tags,
        utcTime: null, viewTime: null
      }
    }

    // clear important booking info that is no longer needed
    booking.viewTime = null;
    booking.utcTime = null;
    booking.selectedMenuOptions = [];
    booking.customer = null;

    return standbyData;
  }

  static getBookingFromStandbyData(standbyData: IStandbyData, booking: IBooking): IBooking {

    const {customer, utcTime, viewTime} = standbyData;

    return {
      ...booking,
      customer,
      utcTime,
      viewTime
    }
  }

  static getBookingOptions(options: IServicePaymentOption[], selectedOptions: IBookingMenuOption[], covers: number, singleMenuPerBooking: boolean, isUpsell = false): IBookingMenuOption[] {

    if (!singleMenuPerBooking) {
      return options.map(option => {

        let item: IBookingMenuOption;
        // if selectedOption exists, use it
        if (!isUpsell) {
          item = selectedOptions && selectedOptions.find(s => s.menuOptionId === option.id);
        } else {
          item = selectedOptions && selectedOptions.find(s => s.menuOptionId === option.id && s.isUpsellItem);
        }
        if (item) {
          return item;
        }

        // otherwise create a new one
        return {
          isUpsellItem: isUpsell,
          menuOptionId: option.id,
          quantity: 0
        };
      });
    }

    if (!selectedOptions || !selectedOptions.length) {
      return [];
    }

    // if the types of options are toggled so get the first and change quantity to covers
    const firstOptWithQuantity: IBookingMenuOption = selectedOptions.find(o => o.quantity > 0);
    if (firstOptWithQuantity) {
      firstOptWithQuantity.quantity = covers;
      return [firstOptWithQuantity];
    }

    return [];
  }

  static getSectionName(filteredSections: ISectionOrder[], activeSectionId: string): string {

    if (activeSectionId === SECTION_ANY_ID) {
      return `${SECTION_ANY_NAME.toLowerCase()} section`;
    }

    const activeSection: ISectionOrder = filteredSections.find(o => o.id === activeSectionId);
    if (!activeSection) {
      return null;
    }

    return `"${activeSection.name}"`;
  }

  static getMenuOptionsPrice(selectedMenuOptions: IBookingMenuOption[], serviceOptions: IServicePaymentOption[]): number {

    if (!selectedMenuOptions || !selectedMenuOptions.length || !serviceOptions) {
      return null;
    }

    let price = 0;
    const priceMap: any = {};

    serviceOptions.forEach(opt => {
      priceMap[opt.id] = opt.price;
    });

    selectedMenuOptions.forEach(option => {
      price += priceMap[option.menuOptionId] * option.quantity;
    });

    return price;
  }


  static isPaymentDetailsVisible(covers: number, paymentDetails: IServicePaymentDetails, isUpsellOptions = false): boolean {

    if (!paymentDetails || !covers) {
      return false;
    }

    if (paymentDetails.price === 0 || paymentDetails.paymentType === servicePaymentType.noPayment) {
      return false;
    }

    return isUpsellOptions || covers >= paymentDetails.peopleRequired;
  }

  static shouldPay(activeService: IScheduleService, booking: IBooking): boolean {
    const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;

    const hasMenuOptions: boolean = BookingService.checkForMenuOptionQuantity(booking.selectedMenuOptions);
    const selectedMenuOptions = booking ? booking.selectedMenuOptions : null;
    let options = paymentDetails ? paymentDetails.options : [];
    if (paymentDetails.optionsUpsell && paymentDetails.optionsUpsell.length) {
      options = [...options, ...paymentDetails.optionsUpsell];
    }
    const menuOptionsPrice: number = BookingService.getMenuOptionsPrice(selectedMenuOptions, options && options.length ? options : null);
    const hasPayment = paymentDetails.paymentType !== servicePaymentType.noPayment;
    // const noMenuOptionsAndEnoughCovers = !hasMenuOptions && booking.covers >= paymentDetails.peopleRequired;
    const isPaymentWithRequiredCovers = hasPayment && booking.covers >= paymentDetails.peopleRequired;

    if (!isPaymentWithRequiredCovers && !hasMenuOptions) {
      return false;
    }

    if (!isPaymentWithRequiredCovers && hasMenuOptions) {
      return menuOptionsPrice > 0;
    }

    return true;

  }

  static checkForMenuOptionQuantity(selectedMenuOption: IBookingMenuOption[]) {
    if (selectedMenuOption && selectedMenuOption.length > 0) {
      return selectedMenuOption.some(menu => menu.quantity > 0);
    } else {
      return false
    }
  }


  static getBookingObj(data: IPrivateFunction | IBookingResponseData): IBooking {

    const bookingMoment = BookingService.getBookingMoment(data.time);
    const selectedOptions = (data as IBookingResponseData).selectedOptions;

    const booking: IBooking = {
      _id: (data as IBookingResponseData)._id || null,
      moment: bookingMoment,
      viewDate: bookingMoment.format('dddd, MMMM D, YYYY'),
      covers: data.people,
      viewTime: bookingMoment.format('h:mm a'),
      rhinoTime: data.time,
      status: (data.status && data.status.statusType) ? data.status.statusType : null,
      serviceId: data.serviceId || null,
      serviceName: data.serviceName || null,
      sectionId: data.preferredSectionId || (data as IPrivateFunction).sectionId || null,
      locked: data.locked || null,
      onlineEditingDisabled: data.onlineEditingDisabled || null,
      isBookedBy: !!data.isBookedBy,
      bookedBy: data.bookedBy || null,

      utcTime: BookingService.getUTCTime(bookingMoment),

      customer: BookingService.getCustomerLikeObjectFromResponse(data as IBookingResponseData),

      payment: data.paymentPending || null,
      notes: (data as IBookingResponseData).notes ? (data as IBookingResponseData).notes : '',
      selectedMenuOptions: selectedOptions ? BookingService.convertSelectedBookingOptions(selectedOptions.filter(opts => !opts.isUpsellItem)) : null,
      selectedUpsellOptions: selectedOptions ? BookingService.convertSelectedBookingOptions(selectedOptions.filter(opts => opts.isUpsellItem)) : null,
      bookingType: (data as IBookingResponseData).bookingType || BookingType.Booking,

      standByConfirmationExpiry: (data as IBookingResponseData).standByConfirmationExpiry || null,

      tables: (data as IBookingResponseData).tables || null,
      hasManagerNotes: (data as IBookingResponseData).hasManagerNotes || false,
      hasCustomerNotes: (data as IBookingResponseData).hasCustomerNotes || false,
      onlineCancelDisabled: data.onlineCancelDisabled || null,
      method: (data as IBookingResponseData).method|| null
    };


    if (data.tags && (data.tags as IBookingTag[]).length) {
      if (data.tags && (data.tags as IBookingTag[]).length) {

        const bookingTags: IBookingTag[] = data.tags as IBookingTag[];
        booking.savedTags = bookingTags.map((bookingTags) => {
          return bookingTags._id;
        });
      } else if (data.tags) {
        booking.savedTags = [(data.tags as IBookingTag)._id];
      }
    }

    if(data.paymentSummary){
      booking.payment.cardLast4 = data.paymentSummary.cardLast4;
      if (data.paymentSummary && data.paymentSummary.amountPaid > 0) {
        booking.payment.amountPaid = data.paymentSummary.amountPaid;
      }
    }

    /**
     * For preauth prevent user from going hitting the payments page twice
     * so use the PreAuthHeldDate because amountPaid will still be zero
     */
    if (data.paymentSummary && data.paymentSummary.paymentType === servicePaymentType.preAuth
      && data.paymentSummary.preAuthHeldDate

      /**
       * Only setting amountPaid if preAuthStatus is holding.
       * If preAuthStatus is 'released' treats the pre-auth as unauthorized/unpaid.
       */
      && data.paymentSummary.preAuthStatus === preAuthStatus.holding
    ) {
      booking.payment.amountPaid = data.paymentSummary.amountDue;
    }

    return booking;
  }

  private static getCustomerLikeObjectFromResponse(data: IBookingResponseData): ICustomer {
    if (data.customer) {
      return data.customer;
    }

    const {firstName, lastName, email, company, phone, notes} = data;

    return {
      firstName, lastName, email, company, phone, notes, subscribed: false
    }
  }

  static getSavedBookingObj(data: IBookingResponseData): ISavedBooking {
    const bookingMoment = BookingService.getBookingMoment(data.time);

    const booking: ISavedBooking = {
      _id: (data as IBookingResponseData)._id || null,
      moment: bookingMoment,
      time: data.time,
      utcTime: BookingService.getUTCTime(bookingMoment),
      viewDate: bookingMoment.format('dddd, MMMM D, YYYY'),
      viewTime: bookingMoment.format('h:mm a'),
      rhinoTime: data.time,
      covers: data.people,
      serviceId: data.serviceId,
      serviceName: data.serviceName || null,
      sectionId: (data.tables.length) ? data.tables[0].sectionId : null,
      bookingId: data._id,
      duration: data.duration,
      tags: (data.tags) ? data.tags : [],
      notes: (data.notes) ? data.notes : '',
      payment: data.paymentPending,
      bookingType: data.bookingType || BookingType.Booking,
      method: data.method
    }

    if(data.paymentSummary){
      booking.payment.cardLast4 = data.paymentSummary.cardLast4;
      if (data.paymentSummary && data.paymentSummary.amountPaid > 0) {
        booking.payment.amountPaid = data.paymentSummary.amountPaid;
      }
    }

    if (data.selectedOptions) {
      booking.selectedMenuOptions = BookingService.convertSelectedBookingOptions(data.selectedOptions);
    }

    return booking
  }

  static getBookingTimeWithOffset(bookingTime: Date, offset: number): Date {
    const bookingTimeWithOffset: Date = cloneDeep(bookingTime);

    if (offset === 0) {
      return bookingTimeWithOffset;
    }

    bookingTimeWithOffset.setHours(bookingTimeWithOffset.getHours() - offset);
    return bookingTimeWithOffset;
  }

  static getVenueTime(activeVenue: IVenue): Date {
    return DateUtilsService.getJsDate(DateUtilsService.getCorrectTime(activeVenue.timeZoneId));
  }

  static canLoadBooking(schedule: ISchedule, savedBooking: ISavedBooking): boolean {
    const service: IScheduleService = schedule.services.find(s => s.id === savedBooking.serviceId);

    if (!service) {
      return false;
    }

    const timeIndex: number = service.times.findIndex(t => t.time === savedBooking.utcTime);

    return (timeIndex >= 0 && savedBooking.duration === service.duration);
  }

  static updateBookingValues(booking: IBooking, newData: IBookingResponseData): IBooking {
    const paymentSummary: IPaymentSummary = newData.paymentSummary;
    const payment: IBookingPayment = booking.payment;
    if (payment && paymentSummary) {
      payment.amountDue = paymentSummary.amountDue;
      payment.amountPaid = paymentSummary.amountPaid;
      payment.paymentType = paymentSummary.paymentType;
    }

    const customer: ICustomer = BookingService.getCustomerLikeObjectFromResponse(newData as IBookingResponseData);

    return {
      ...booking,
      covers: newData.people,
      customer,
      tags: newData.tags,
      status: newData.status.statusType,
      payment,
      serviceId: newData.serviceId,
      serviceName: newData.serviceName,
      locked: newData.locked,
      onlineEditingDisabled: newData.onlineEditingDisabled
    };
  }

  private static getEmptyCustomer(): ICustomer {
    return {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      subscribed: false
    }
  }

  /**
   * Booking moment displaying browser time so time will always
   * display correct even if the customer is in a different timezone
   */
  private static getBookingMoment(time: string): moment.Moment {
    return moment(time, 'YYYY/MM/DD HH:mm', true);
  }

  /**
   * Remove offset from utcTime as time as we dont know what timezone the
   * browser may be set to
   */
  private static getUTCTime(bookingMoment: moment.Moment): string {
    const utcTime: string = bookingMoment.format();
    return utcTime.substring(0, utcTime.length - 6);
  }

  static convertSelectedBookingOptions(opts: ISavedBookingSelectedOptions[]): IBookingMenuOption[] {

    const returnVal: IBookingMenuOption[] = opts.reduce((acc, {id, quantity, extras, isUpsellItem}) => {

      const opt: IBookingMenuOption = {menuOptionId: id, quantity, isUpsellItem};
      if (extras && extras.length) {
        opt.extras = MenuOptionsService.getEmptyExtrasMenuOption();

        const explicitChildMenuOptions = extras.filter(o => o.isExplicit);
        const implicitChildMenuOptions = extras.filter(o => !o.isExplicit);

        // this is a multi-dimensional array, but only the first item is populated
        opt.extras.explicitChildMenuOptions = [
          explicitChildMenuOptions.map(o => ({menuOptionId: o.id, quantity: o.quantity}))
        ];

        opt.extras.implicitChildMenuOptions = implicitChildMenuOptions.map(o => ({
          menuOptionId: o.id,
          quantity: o.quantity
        }));
      }

      const existingOptWithSameId: IBookingMenuOption = acc.find(({menuOptionId}) => opt.menuOptionId === menuOptionId);
      if (existingOptWithSameId) {
        this.combineMenuOptions(existingOptWithSameId, opt);
      } else {
        acc.push(opt);
      }

      return acc;
    }, []);

    this.fillEmptyExplicitSlots(returnVal);

    return returnVal;
  }

  private static fillEmptyExplicitSlots(opts: IBookingMenuOption[]): void {
    // fill `explicitChildMenuOptions` empties if there are any
    opts.forEach(opt => {
      if (opt.extras && !opt.extras.isSameForAll && opt.extras.explicitChildMenuOptions.length !== opt.quantity) {
        const start = opt.extras.explicitChildMenuOptions.length;
        for (let i = start; i < opt.quantity; i++) {
          opt.extras.explicitChildMenuOptions.push([]);
        }
      }
    });
  }

  /**
   * Merges props from opt2 into opt1
   */
  private static combineMenuOptions(opt1: IBookingMenuOption, opt2: IBookingMenuOption): IBookingMenuOption {
    opt1.quantity += opt2.quantity;
    if (opt2.extras) {
      opt1.extras = opt1.extras || MenuOptionsService.getEmptyExtrasMenuOption();
      opt1.extras.explicitChildMenuOptions = [
        ...opt1.extras.explicitChildMenuOptions,
        ...opt2.extras.explicitChildMenuOptions
      ];
      opt1.extras.implicitChildMenuOptions = [
        ...opt1.extras.implicitChildMenuOptions,
        ...opt2.extras.implicitChildMenuOptions
      ];
    }

    if (opt1.extras) {
      opt1.extras.isSameForAll = this.menuOptionExtraIsSameForAll(opt1.extras.explicitChildMenuOptions[0], opt1.quantity);
    }
    return opt1;
  }

  // we can figure out if `isSameForAll` has been checked by seeing if there are more than 1 quantity for `explicitChildMenuOptions`
  private static menuOptionExtraIsSameForAll(explicitChildMenuOptions: IBookingMenuOption[], parentQuantity: number): boolean {
    const firstExplicitOpt = explicitChildMenuOptions && explicitChildMenuOptions.length && explicitChildMenuOptions[0];
    return !firstExplicitOpt || parentQuantity === 1 || firstExplicitOpt.quantity > 1;
  }

  static isStandby(isStandByList: boolean, day: string, standByListDaysWeekDays: string[]): boolean {
    if (!isStandByList) {
      return isStandByList;
    }
    return standByListDaysWeekDays.includes(day);
  }

  static getSelectionData(selectedUpsellOptions: IBookingMenuOption[], upsellHeading: string, upsellDescription: string): IBookingMenuOptionExtrasUpdater {
    return {
      explicitChildMenuOptions: [],
      implicitChildMenuOptions: selectedUpsellOptions,
      isSameForAll: true,
      parentLabel: upsellHeading,
      upsellDescription,
      parentQuantity: 0
    }
  }

  /**
   * Get additional booking requirements for the active venue
   */
  static getAdditionalBookingRequirements(widget: IWidgetModel): IAdditionalBookingRequirements {
    if (!widget) return null;
    const {accountDetails, activeVenue} = widget;
    const activeVenueDetails = accountDetails?.ownedVenues?.find(venue => venue.id === (activeVenue as IVenue).id);
    return activeVenueDetails?.additionalBookingRequirements;
  }

  static getTimesFromAllServices(schedules: ISchedule[], firstServiceTime: IScheduleTime): IScheduleTime[] {
    const allTimes: IScheduleTime[][] = [];
    if (!isEmpty(firstServiceTime)) {
      allTimes.push([firstServiceTime]); // adding the 1st available service time
    }

    schedules.forEach((schedule: ISchedule) => {
      schedule.services.forEach(service => allTimes.push(formatServiceTimes(service.times.filter(time => this.shouldShowTimeInterval(time)))))
    })

    return orderBy(uniqBy(flatten(allTimes), 'time'), 'time');
  }

  static getFilteredTimesForVenue(services: IScheduleService[], selectedTime: IScheduleTime): IScheduleTime[] {
    const allTimes: IScheduleTime[][] = [];
    services.map(service => allTimes.push(formatServiceTimes(service.times.filter(time => this.shouldShowTimeInterval(time)))));
    let times: IScheduleTime[];
    const filteredTimes = sortBy(uniqBy(flatten(allTimes), 'time'), 'time');
    times = this.addingTimes(selectedTime, filteredTimes);

    // if times is empty means the selectedTime is not found - then get the nearest before time and 2 nearest future times
    if (isEmpty(times)) {
      times = this.getTimesIfSelectedNotFound(selectedTime.time, filteredTimes);
    }
    return sortBy(times, 'time'); // return times sorted by earliest
  }

  private static getTimesIfSelectedNotFound(time: string, filteredTimes: IScheduleTime[]): IScheduleTime[] {
    const selectedTime = moment(time);
    const beforeTime: IScheduleTime[] = [];
    const afterTime: IScheduleTime[] = [];
    filteredTimes.map((time) => {
      const momentTime = moment(time.time);
      if (momentTime.isBefore(selectedTime)) {
        beforeTime.push(time);
      } else {
        afterTime.push(time);
      }
    });
    return [...beforeTime.slice(-1), ...afterTime.slice(0, 2)]; // get the last earlier time and 1st 2 future times
  }

  private static addingTimes(selectedTime: IScheduleTime, filteredTimes: IScheduleTime[]) {
    const times: IScheduleTime[] = []
    filteredTimes.forEach((time, index) => {
      if (time.time === selectedTime.time) {
        times.push(time); // if time found add it to the list
        if (index > 0) {
          times.push(filteredTimes[index - 1]); // add one previous time to the list if there is one
        }
        if (index < filteredTimes.length - 1 && index !== filteredTimes.length - 1) { // if the selected time is the last time do nothing
          times.push(filteredTimes[index + 1]); // push the next time after the selected time
          if ((filteredTimes.length - 1) - index >= 2) {
            times.push(filteredTimes[index + 2]); // if there are 2 times available from the selected one add another
          }
        }
      }
    })
    return times;
  }

  /**
   * Determine whether to show/hide the time interval.
   */
  static shouldShowTimeInterval(time: IScheduleTime) {

    // Must've not expired
    if (time.expired) return false;

    // When table is available then, display time pill
    if (this.isTableAvailable(time.sections)) return true;

    // Hide the time pill
    return false;
  }

  /**
   * Check standby eligibility
   */
   static isStandbyEligible(widget: IWidgetModel) {

    const { booking, activeVenue, filteredTimes } = widget;

    const isStandbyEnabled = BookingService.isStandby(activeVenue.widgetSettings.standByList, booking.moment.format('dddd'), activeVenue.widgetSettings.standByListDaysWeekDays);

    const hasDisabledTimes = filteredTimes && filteredTimes.some(t => t.isDisabled && !t.isBlocked);

    // Should've a disabled time and standby must be enabled
    return isStandbyEnabled && hasDisabledTimes;
  }

  static getActiveServiceForGAW(schedule: ISchedule, time: string) {
    return schedule.services.filter(service => this.getServicesBasedOnTime(service, time));
  }

  static getServicesBasedOnTime(service: IScheduleService, time: string) {
    return service.times.some(serviceTime => serviceTime.time === time);
  }

  static isTableAvailable(sections: ISectionState[]) {
    return sections.some(s => s.sectionState);
  }

  static generateGAWUrl(accountId: string, venue: IOwnedVenue, date: string, covers: number, time: string) {

    const widgetSettings = venue.widgetSettings
    const isUsingWidgetV2 = true;
    const widgetUrl = appValues.URLS.widgetV2URL;

    let otherVenueURL = widgetUrl + (
      isUsingWidgetV2 ? '' : 'booking'
    );

    const mDate = moment(date).format('YYYY-MM-DD');

    otherVenueURL += `?accountid=${accountId}`
      + (widgetSettings.theme ? `&theme=${widgetSettings.theme}` : '')
      + (widgetSettings.accentColour ? `&accent=${widgetSettings.accentColour}` : '')
      + `&venueid=${venue.id}`
      + (widgetSettings.font ? `&font=${widgetSettings.font}` : '')
      + `&covers=${covers}`
      + `&date=${mDate}`
      + `&time=${time}`;

    otherVenueURL = encodeURI(otherVenueURL.trim()); // encoding the url to fix the issue with multiple words in the url

    return otherVenueURL;
  }

  static checkForGAWThreshold(filteredTimes: ISelectableTime[], threshold: number) {
    // isBlocked is not relevant to calculations since blocked out times are never available
    const percentUnavailable: number = filteredTimes ? (filteredTimes.filter(t => t.isDisabled && !t.expired).length / filteredTimes.length) * 100 : 100;
    console.log('percentUnavailable', percentUnavailable)
    return percentUnavailable >= threshold;
  }

  static validatingOtherVenues(allSchedule: ISchedule[], ownedVenues: IOwnedVenue[], booking: IBooking): ISchedule[] {

    return allSchedule.filter((schedule: ISchedule) => schedule.isVenueOpen && this.validatingVenues(ownedVenues.find(v => v.id === schedule.venueId), booking));
  }

  private static validatingVenues(venue: IOwnedVenue, booking: IBooking): boolean {
    const widgetSettings = venue.widgetSettings;
    const maxPax = widgetSettings.maxPeoplePerBooking;
    const covers = booking.covers;
    const maxDayInFuture = venue.widgetSettings.maxDaysInFutureBooking;
    const bookingDate = booking.moment;
    const venueDate = moment().add(maxDayInFuture, 'days');
    const allowOnlineBookings = widgetSettings.allowOnlineBookings;
    const isCoversOK = !maxPax || covers <= maxPax;
    const venueDateCheck = maxDayInFuture ? bookingDate.toDate() <= venueDate.toDate() : true;

    return allowOnlineBookings && isCoversOK && venueDateCheck // return true if conditions for maxPeoplePerBooking and maxDaysInFutureBooking are met
  }

  static findMaxPeopleOverrideFromServices(schedule: ISchedule): number {
    return schedule && schedule.services.length > 0 ? Math.max(...schedule.services?.map(o => o.paymentDetails?.maxPeoplePerBookingOverride)) : null; // returns the max override value from the services
  }

  static correctSelectedMenuOptions({activeService, booking}: { activeService: IScheduleService, booking: IBooking }): IBookingMenuOption[] {
    return !activeService
      ? booking.selectedMenuOptions
      : activeService.paymentDetails.options.find(({ id }) => id === booking.selectedMenuOptions[0]?.menuOptionId) // selectedMenuOptions have 1 item only
        ? booking.selectedMenuOptions
        : []
  }
}

function formatServiceTimes(times: IScheduleTime[]): IScheduleTime[] {
  return times.map((time) => {
    return {...time, time: moment(time.time).format('YYYY-MM-DDTHH:mm:ss')}; // Fixes the DateTime String having different Dates
  })
}
