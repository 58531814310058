import {Observable, from, of, throwError} from "rxjs";
import axios, { AxiosInstance, AxiosAdapter, AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import {IResponse} from 'app/containers/App/types';
import appValues from 'app/constants/appValues';
import {catchError, map, tap} from "rxjs/operators";
import { IAccountDetails } from "../account/account.types";
import { Moment } from "moment";
import {
  IScheduleParams,
  ISchedule,
  IGetBookingParams,
  IMenuOptionsResponse,
  IPaymentType,
  IStandbyRequest,
  INabSearchResponse
} from "./client.types";
import { ICreditCardEncrypted } from "../payment/payment.types";
import { IBookingOutgoing, IBookingResponseData, ISavedBookingMenuOption } from "../booking/booking.types";

const NS = 'ClientService';

// @toDo: check cache time required. severity: high
const cache = setupCache({
  maxAge: 24 * 60 * 60 * 1000 // cached for 1 day
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
  adapter: cache.adapter as AxiosAdapter
});

/**
 * Should only get called once per session/app load.
 * Used for better SEQ logging.
 * Same thing also in PaymemtService.
 */
const requestInterceptor = (config: AxiosRequestConfig) => {
  config.headers['X-NBI-CorrelationId'] = appValues.GLOBAL_SESSION_CORRELATION_ID;
  config.headers['X-NBI-Source'] = 'widget2';
  return config;
};
api.interceptors.request.use(requestInterceptor);

export class ClientService {

  static getCancelBookingUrl(venueId: number, bookingToken: string): string {
    return `${appValues.APIBASE}/bookings/cancel-booking/venues/${venueId}` + (bookingToken ? `?token=${bookingToken}` : '');
  }

  static getScheduleUrl(venueId: number): string {
    return `${appValues.APIBASE}/bookings/get-schedule/venue/${venueId}`;
  }

  static getAvailableSchedulesUrl(venueId: number): string {
    return `${appValues.APIBASE}/bookings/find-next-available-schedule-v2/by-venue/${venueId}`;
  }

  static getAllScheduleUrl(venueId: number): string {
    return `${appValues.APIBASE}/group-availability/venues/${venueId}/schedules`;
  }

  static getPaymentTypeUrl(venueId: number): string {
    return `${appValues.APIBASE}/bookings/payments/venues/${venueId}/calculate-payment`;
  }

  static getAxiosInstance(): AxiosInstance {
    return api;
  }

  static getAccount(id: string): Observable<IResponse<IAccountDetails>> {
    return from(
      api.get(appValues.APIBASE + '/bookings/accounts/' + id)
    );
  }

  /**
   * Loads the schedule for a date
   * @param bookingId on back end, when providing bookingId, it will remove the booking from the bookings list,
   *  so that it can become available again. This is useful when editing an existing booking.
   */
  static getSchedule(date: Moment, covers: number, venueId: number, bookingId?: string): Observable<ISchedule> {

    const params: IScheduleParams = {
      date: date.format('YYYY-M-D'),
      numofpeople: covers
    };

    if (bookingId) {
        params.bookingId = bookingId;
    }

    return from(
      api.get(this.getScheduleUrl(venueId), {params})
    ).pipe(
      map(({data}: any) => data as ISchedule),
    );
  }

  /**
   * Loads the prev/next available schedules for the provided date
   */
   static getAvailableSchedules(date: Moment, covers: number, venueId: number, isLookForward: boolean): Observable<INabSearchResponse> {

    const params: IScheduleParams = {
      date: date.format('YYYY-M-D'),
      numofpeople: covers,
      isLookForward: isLookForward
    };

    return from(
      api.get(this.getAvailableSchedulesUrl(venueId), {params})
    ).pipe(
      map(({data}: any) => data as INabSearchResponse),
    );
  }

  static getAllSchedule(date: Moment, covers: number, venueId: number, bookingId?: string): Observable<ISchedule[]> {

    const params: IScheduleParams = {
      date: date.format('YYYY-M-D'),
      numofpeople: covers
    };

    if (bookingId) {
      params.bookingId = bookingId;
    }

    return from(
      api.get(this.getAllScheduleUrl(venueId), {params})
    ).pipe(
      map(({data}: any) => data as ISchedule[]),
    );
  }

  static getBooking(bookingTokenId: string, venueId: number): Observable<IResponse<IBookingResponseData>> {
    const params: IGetBookingParams = {
      token: bookingTokenId
    }
    return from(
      api.get(`${appValues.APIBASE}/bookings/venues/${venueId}`, {params})
    );
  }

  static getBookingById(bookingId: string, venueId: number, isCache = false): Observable<IResponse<IBookingResponseData>> {
    const cacheControl = !isCache ? `?cache-control=no-cache` : '';
    return from(
      api.get(`${appValues.APIBASE}/bookings/venues/${venueId}/bookings/${bookingId}${cacheControl}`)
    );
  }

  /**
   * Takes menuOption ids and response with full details of those menuOptions
   * @param childMenuOptionIds - comma separated list of menuOption ids to query
   * @param venueId
   */
  static getBookingOptions(childMenuOptionIds: string, venueId: number): Observable<IMenuOptionsResponse> {
    if (!childMenuOptionIds) {
      return of(null)
    }
    return from(
      api.post(`${appValues.APIBASE}/bookings/venues/${venueId}/booking-options`, {childMenuOptionIds})
    );
  }

  /**
   * Gets the paymentType and amount.
   * `selectedMenuOptions` must be in save format as when saving booking (transform using `menuOptionsService.getFlatExtras`).
   * Sample Response
   * {
   *   "amount": 132.5,
   *   "paymentTypeName": "FullPayment"
   * }
   */
  static getPaymentType(
    venueId: number, selectedMenuOptions: ISavedBookingMenuOption[], serviceId: string, bookingDateTime: string,
    covers: number, bypassValidation = false
  ): Observable<IResponse<IPaymentType>> {
    return from(
      api.post(this.getPaymentTypeUrl(venueId), {
        serviceId,
        time: bookingDateTime,
        numberOfPeople: covers,
        selectedMenuOptions,
        bypassValidation
      })
    );
  }

  static saveToStandbyList(standbyData: IStandbyRequest, venueId: number): Observable<IResponse<any>> {
    return from(
      api.post(`${appValues.APIBASE}/bookings/venues/${venueId}/add-standby`, standbyData)
    );
  }

  static saveBooking(booking: IBookingOutgoing, venueId: number): Observable<IResponse<IBookingResponseData>> {
    return from(
      api.post(`${appValues.APIBASE}/bookings/save-new-booking/venue/${venueId}`, booking)
    );
  }

  static updateBooking(bookingId: string, booking: IBookingOutgoing, venueId: number): Observable<IResponse<IBookingResponseData>> {
    return from(
      api.patch(`${appValues.APIBASE}/bookings/venues/${venueId}/bookings/${bookingId}`, booking)
    );
  }


  /**
   * Actually deletes the booking (all records of it). Used when a user gets to payment page, but then cancels.
   * Similar to cancelBooking.
   */
  static deleteBooking(bookingId: string, venueId: number): Observable<any> {
    return from(
      api.delete(`${appValues.APIBASE}/bookings/venues/${venueId}/delete-booking?bookingId=${bookingId}`)
    );
  }

  /**
   * Cancels a booking that has been saved (if payment is required, then it has also passed the payment page).
   * Similar to deleteBooking.
   */
  static cancelBooking(bookingToken: string, venueId: number): Observable<IResponse<IBookingResponseData>> {

    // just for testing
    // return throwError({response: {
    //     statusText: 'Server Error',
    //     status: 500,
    //     data: {
    //       message: 'Testing error response'
    //     }
    //   }})

    return from(
      api.post(this.getCancelBookingUrl(venueId, bookingToken))
    );
  }

  static confirmBooking(bookingToken: string, venueId: number): Observable<IResponse<IBookingResponseData>> {
    // just for testing
    // return throwError({response: {
    //   statusText: 'Server Error',
    //   status: 500,
    //   data: {
    //     message: 'Testing error response'
    //   }
    // }})

    return from(
      api.post(`${appValues.APIBASE}/bookings/venues/${venueId}/confirm-booking?token=${bookingToken}`)
    );
  }
}
